import React from "react";
import "./styles.css";
import Select, { createFilter } from "react-select";
import SETTINGS from './Settings';

class CertificadosScreen extends React.Component {
	initialState = {
		vehiculo: {},
		nuevolimite: "",
		listaActivos: [],
		url_cert_generado: '',
		cargandoInfoCertificado: false,
	};


	constructor(props) {
		super(props);
		this.state = { ...this.initialState };
	}

	volverAPrincipal = () => {
		window.location.reload();
	}

	componentDidMount() {
		this.setState({
			listaActivos: this.props.data.length < 128 ?
				this.props.data.activos.map(a => {
					return {
						value: a,
						label: a.ID + " - " + a.DESCRIPCION
					};
				}) :
				this.props.data.activos.map(a => {
					return {
						value: a,
						label: a.PLACA
					};
				})
		})
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	generarCertificado = () => {
		let activoSeleccionado = this.state.vehiculo && this.state.vehiculo.value;
		if (!activoSeleccionado || !activoSeleccionado.PLACA) {
			alert("Favor seleccione un vehículo del listado");
			return;
		}
		this.setState({cargandoInfoCertificado:true});
		fetch('//certificados.boltrack.net/api/generar_certificado', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				activo: activoSeleccionado,
			})
		}).then((response) => {
			return response.json();
		}).then((data) => {
			console.log(data);
			if (data.status) {
				console.log(data.url_cert_generado);
				window.open(data.url_cert_generado, '_blank');
				this.setState({
					url_cert_generado: data.url_cert_generado,
					validez_hasta: data.validez_hasta,
					cargandoInfoCertificado: false,
				})
			} else {
				this.setState({
					cargandoInfoCertificado: false,
				});
				alert(data.mensaje);
			}
		
		}).catch(e => {
			console.log(e.message);
			console.log(e.stack);
			this.setState({
				cargandoInfoCertificado: false,
			})
		});

	}

	render() {
		let cargandoInfoCertificado = this.state.cargandoInfoCertificado;
		return (
			<>
				<h1>
					<img
						id="logo"
						src="https://gestiondeflota.boltrack.net/static/media/logo_bgwhite.ef3bb423.png"
						alt="logo Boltrack SRL"
					/>
				</h1>
				<h2>{SETTINGS.titulo}</h2>
				<div id="loginBox">
					<div className="fldSep">
						<span>Placa:</span>
						<Select
							className="selectWrapper"
							value={this.state.vehiculo}
							filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
							onChange={vehiculo => {
								this.setState({ vehiculo: vehiculo });
							}}
							options={this.state.listaActivos}
						/>
					</div>
					{false &&<div className="fldSep">
						<span>{JSON.stringify(this.state.vehiculo, null, 2)}</span>
					</div>}
					<button onClick={this.generarCertificado} className="positive">
						{cargandoInfoCertificado ? (
							<img
								src="https://i.imgur.com/LSRNICX.gif"
								style={{ width: "40px" }}
								alt="spinner"
							/>
						) : (
								"Generar Certificado"
							)}
					</button>
					<button onClick={this.volverAPrincipal} className="neutral">
							&lt;&lt; VOLVER
							</button>
				</div>
			</>
		);
	}
}

export default CertificadosScreen;
