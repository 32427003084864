import React from "react";
import "./styles.css";
import SETTINGS from './Settings';

class CertificadosLoginScreen extends React.Component {
	initialState = {
		usuario: "",
		password: "",
		cargando: false,
		cargandoValidacion: false,
		codigo: "",
		enVistaDeCertificado: false,
		infoCertificado: null,
	};

	spinnerTimeoutID;

	constructor(props) {
		super(props);
		this.state = { ...this.initialState };
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	enviarDatos = () => {
		this.setState({ cargando: true }, () => {
			this.props.onSubmit(this.state.usuario, this.state.password, this.state.codigo);
			this.spinnerTimeoutID = setTimeout(() => {
				this.setState({ cargando: false });
			}, 1000);
		});
	};

	validarCertificado = () => {
		this.setState({ cargandoValidacion: true })
		/*setTimeout(() => {
			this.setState({
				cargandoValidacion: false,
				enVistaDeCertificado: true,
			})
		}, 1500)*/
		fetch('//certificados.boltrack.net/api/validar_certificado', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				codigo: this.state.codigo.trim(),
			})
		}).then((response) => {
			return response.json();
		}).then((data) => {
			console.log(data);
			if (data.infoCertificado && data.infoCertificado.valido) {
				this.setState({
					cargandoValidacion: false,
					enVistaDeCertificado: true,
					infoCertificado: data.infoCertificado, /*valido*/
				});
			} else {
				this.setState({
					cargandoValidacion: false,
					infoCertificado: data.infoCertificado
				});
				alert(data.mensaje || (data.infoCertificado && data.infoCertificado.mensaje));
			}
			
		}).catch(e => {
			console.log(e.message);
			console.log(e.stack);
		});
	};

	volverAPrincipal = () => {
		this.setState({ cargandoValidacion: false, enVistaDeCertificado: false })
	}

	componentWillUnmount() {
		if (this.spinnerTimeoutID) {
			clearTimeout(this.spinnerTimeoutID);
			this.spinnerTimeoutID = undefined;
		}
	}

	descargarCopia = () => {
		if (this.state.infoCertificado && this.state.infoCertificado.url) {
			window.open(this.state.infoCertificado.url, '_blank');
		}
	}

	render() {
		let mostrarSpinner = this.state.cargando;
		let mostrarSpinnerValidacion = this.state.cargandoValidacion;
		let certStatus = "N/A";
		let infoCertificado = this.state.infoCertificado;
		if (this.state.infoCertificado) {
			if (this.state.infoCertificado.valido) {
				certStatus = <span style={{color:'green'}}>VALIDO</span>
			} else {
				certStatus = <span style={{color:'red'}}>INVALIDO/ERRONEO</span>
			}
		}
		return (
			<>
				<h1>
					<img
						id="logo"
						src="https://gestiondeflota.boltrack.net/static/media/logo_bgwhite.ef3bb423.png"
						alt="logo Boltrack SRL"
					/>
				</h1>
				{!this.state.enVistaDeCertificado &&
					<>
						<h2>{SETTINGS.titulo}</h2>
						<div id="loginBox">
							<div className="fldSep">
								<span>Usuario:</span>
								<input
									id="usr"
									type="text"
									name="usuario"
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="fldSep">
								<span>Password:</span>
								<input
									id="pwd"
									type="password"
									name="password"
									onChange={this.handleInputChange}
								/>
							</div>
							{this.props.errorTxt && (
								<span className="errorTxt">{this.props.errorTxt}</span>
							)}
							<button onClick={this.enviarDatos} className="positive">
								{mostrarSpinner ? (
									<img
										src="https://i.imgur.com/LSRNICX.gif"
										style={{ width: "40px" }}
										alt="spinner"
									/>
								) : (
										"INGRESAR"
									)}
							</button>
						</div>
						<br />
						<br />
						<h2>Validar certificado</h2>
						<div id="loginBox">
							<div className="fldSep">
								<span>Código de Verificacion:</span>
								<input
									id="usr"
									type="text"
									name="codigo"
									onChange={this.handleInputChange}
								/>
							</div>
							<button onClick={this.validarCertificado} className="positive">
								{mostrarSpinnerValidacion ? (
									<img
										src="https://i.imgur.com/LSRNICX.gif"
										style={{ width: "40px" }}
										alt="spinner"
									/>
								) : (
										"VALIDAR"
									)}
							</button>
						</div>
					</>
				}
				{this.state.enVistaDeCertificado &&
					<>
						<button onClick={this.volverAPrincipal} className="neutral">
							&lt;&lt; VOLVER
							</button>
							<br/>
						{this.state.infoCertificado &&
							<>
								<h2>Certificado es {certStatus}</h2>
								<div id="loginBox">
									<div className='contenedorTabla'>
										<div class='filaTabla'>
											<div className='categoriaTabla'>Placa:</div>
											<div className='valorTabla'>{infoCertificado.placa}</div>
										</div>
										<div className='filaTabla'>
											<div className='categoriaTabla'>Clase:</div>
											<div className='valorTabla'>{infoCertificado.clase}</div>
										</div>
										<div className='filaTabla'>
											<div className='categoriaTabla'>Marca:</div>
											<div className='valorTabla'>{infoCertificado.marca}</div>
										</div>
										<div className='filaTabla'>
											<div className='categoriaTabla'>Modelo:</div>
											<div className='valorTabla'>{infoCertificado.modelo}</div>
										</div>
										<div className='filaTabla'>
											<div className='categoriaTabla'>Color:</div>
											<div className='valorTabla'>{infoCertificado.color}</div>
										</div>
									</div>
									<button onClick={this.descargarCopia} className="positive">
										Descargar copia del certificado.
									</button>
								</div>
							</>
						}
					</>
				}
			</>
		);
	}
}

export default CertificadosLoginScreen;
