import React from "react";

import SETTINGS from './Settings'

import "./styles.css";

class MainScreen extends React.Component {
  initialState = {
    token: "",
    empresa: {},
    usuario: {},
    activos: [],
    username: "",
    password: "",
    errorTxt: ""
  };

  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  //http://184.107.245.202:8901/controlvel_login

onSubmit = (usuario, password) => {
   fetch(SETTINGS.login_url, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
      body: JSON.stringify({user:usuario,pass:password})
    }).then((response) => {
      return response.json();
    }).then((data)  =>  { 
      if (data && data.empresa) {
        this.setState(data);
      } else {
        this.setState({errorTxt:data.mensaje})
      }
    });
}
/*
  onSubmit = (usuario, password) => {
    setTimeout(() => {
      this.setState({
        username: usuario,
        password: password,
        token: "AAAVVVVBBBB",
        empresa: { id: 1740, nombre: "Sudamericana" },
        usuario: { nombre: "Juan Perez Perei" },
        activos: [
          {
            ID: "IDVEH1",
            PLACA: "PLACAT1",
            ESN: "45646545",
            SIM: "71245785",
            DESCRIPCION: "MAZDA ESCUDO",
            ULTREPORTE: "15 min."
          },
          {
            ID: "IDVEH2",
            PLACA: "PLACAV2",
            ESN: "45646545",
            SIM: "71245785",
            DESCRIPCION: "SUZUKI VITARA",
            ULTREPORTE: "15 min."
          }
        ]
      });
    }, 1000);
  };*/

  render() {
    return this.state.empresa && this.state.empresa.id ? (
      <SETTINGS.componente data={this.state} />
    ) : (
      <SETTINGS.componentelogin errorTxt={this.state.errorTxt} onSubmit={this.onSubmit} />
    );
  }
}

export default MainScreen;
