import React from "react";
import "./styles.css";
import Select, { createFilter } from "react-select";

class FormScreen extends React.Component {
  initialState = {
    esnseleccionado: {},
    nuevolimite: "",
  };

  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
	};
	
	confirmarCambio = () => {
		let datosVehic = this.props.data.activos.find(p => p.ESN === this.state.esnseleccionado.value);
		console.log(datosVehic);
		console.log(this.state.esnseleccionado);
		let errorTxt = "";
		let esPlacaValida = this.state.esnseleccionado !== "";
		let esVelocidadEntera = Number.isInteger(+this.state.nuevolimite);
		let esPositiva = +this.state.nuevolimite > 0;
		let estaEnLimites = +this.state.nuevolimite > 30;
		if (!esPlacaValida) errorTxt = "Seleccione un vehiculo del listado";
		else if (!esVelocidadEntera) errorTxt = "El nuevo limite debe ser un numero entero";
		else if (!esPositiva) errorTxt = "El nuevo limite debe ser un numero positivo";
		else if (!estaEnLimites) errorTxt = "El nuevo limite debe ser mayor a 30 KM/h";
		if (errorTxt) {
			alert(errorTxt);
		} else {
			let confirmacion = window.confirm('Cambiar el limite de velocidad de '+datosVehic.ID+' a '+this.state.nuevolimite+" km/h?");
			if (confirmacion) {
				fetch('http://192.175.109.132:8901/controlvel_cambiarvel/', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						esn: datosVehic.ESN,
						sim: datosVehic.SIM,
						nuevavel: this.state.nuevolimite,
						username: this.props.data.username,
					})
				}).then((response) => {
					return response.json();
				}).then((data)  =>  { 
					alert(data.mensaje);
				});
			}
		}
	}

  render() {
    return (
      <>
        <h1>
          <img
            id="logo"
            src="https://gestiondeflota.boltrack.net/static/media/logo_bgwhite.ef3bb423.png"
            alt="logo Boltrack SRL"
          />
        </h1>
        <h2>Cambio de Limites</h2>
        <div id="loginBox">
          <div className="fldSep">
            <span>Placa:</span>
            <Select
              className="selectWrapper"
							value={this.state.esnseleccionado}
							filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
              onChange={nuevoesn => {
                this.setState({ esnseleccionado: nuevoesn });
              }}
              options={this.props.data.activos.map(a => {
                return {
                  value: a.ESN,
                  label: a.ID + " - " + a.DESCRIPCION
                };
              })}
            />
          </div>
          <div className="fldSep">
            <span>Nuevo Limite KM/h:</span>
            <input
              id="nuevolimite"
              type="number"
              name="nuevolimite"
              onChange={this.handleInputChange}
            />
          </div>
          <button onClick={this.confirmarCambio} className="positive">
            ENVIAR CAMBIO
          </button>
        </div>
      </>
    );
  }
}

export default FormScreen;
